import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, mergeMap, Observable, of, take } from 'rxjs';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';
import { OutRealEstateDTO, RealEstateDTO, RebuildEstateDTO } from '@generated/generatedEntities';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { LocationStrategy } from '@angular/common';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@app/@shared';
import { SmallBuildingRestService } from '@app/calculators/services/small-building-service/small-building-rest.service';
import { RebuildEstateDataService } from '../rebuild/rebuild-estate-data/rebuild-estate-data.service';
import { RebuildEstateService } from '../rebuild/rebuild-estate.service';
import { LccDataService } from '@app/calculators/lcc-form/lcc-data.service';
import { PresetWrapperService } from '@app/calculators/services/preset/preset-wrapper.service';
import { IframeInput } from '@generated/extended';
import { RealEstateRepository } from '@app/core/services/state/realEstate/real-estate.repository';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RealEstateActions } from '@app/core/services/store/real-estate.actions';

const log = new Logger('RebuildEstateResolver');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RealEstateResolverResolver {
  private realEstateService = inject(RealEstateService);
  private router = inject(Router);
  private credentialsService = inject(CredentialsService);
  private url = inject(LocationStrategy);
  private authenticationService = inject(AuthenticationService);
  private _smallBuildingService = inject(SmallBuildingRestService);
  private _rebuildEstateService = inject(RebuildEstateService);
  private _rebuildEstateDataService = inject(RebuildEstateDataService);
  private lccDataService = inject(LccDataService);
  private presetWrapperService = inject(PresetWrapperService);
  private realEstateRepository = inject(RealEstateRepository);
  private store = inject(Store);

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RealEstateDTO | RebuildEstateDTO | IframeInput> {
    let realEstateIdByStateParam = this.router.getCurrentNavigation()?.extras.state?.['realEstateId'];
    let realEstateByStateParam!: RealEstateDTO;
    this.store.dispatch(RealEstateActions.resetStore());
    if (!realEstateIdByStateParam) {
      // console.warn('realestateId state is not held on page refresh so, getting realestate from sessionstorage'); // TBC: LUKAS
      realEstateIdByStateParam = parseInt(sessionStorage.getItem('realEstateId') || '');
    }
    let realEstate = this.router.getCurrentNavigation()?.extras.state?.['realEstate'];
    const isReferenceObj: boolean = this.router.getCurrentNavigation()?.extras.state?.['referenceObject'] ?? false;
    if (isReferenceObj) {
      const wpt =
        this.router.getCurrentNavigation()?.extras.state?.['withdrawalProductTypes'].toLocaleUpperCase() ?? '';
      return this.realEstateService.getEmptyRealEstate(wpt).pipe(
        switchMap((realEstateDTO) => {
          realEstateDTO.quality.qualitaetGrundstueck = realEstate.quality.qualitaetGrundstueck;
          realEstateDTO.quality.qualitaetGrundstueckBackend = realEstate.quality.qualitaetGrundstueckBackend;
          realEstateDTO.quality.qualitaetvorbereitungsarbeiten = realEstate.quality.qualitaetvorbereitungsarbeiten;
          realEstateDTO.quality.qualitaetvorbereitungsarbeitenBackend =
            realEstate.quality.qualitaetvorbereitungsarbeitenBackend;
          realEstateDTO.quality.qualitaetVorfertigung = realEstate.quality.qualitaetVorfertigung;
          realEstateDTO.quality.qualitaetVorfertigungBackend = realEstate.quality.qualitaetVorfertigungBackend;
          realEstateDTO.quality.qualitaetDerGebaeudeform2 = realEstate.quality.qualitaetDerGebaeudeform2;
          realEstateDTO.quality.qualitaetDerGebaeudeform2Backend = realEstate.quality.qualitaetDerGebaeudeform2Backend;
          realEstateDTO.quality.fensterAnteil = realEstate.quality.fensterAnteil;
          realEstateDTO.quality.fensterAnteilBackend = realEstate.quality.fensterAnteilBackend;
          realEstateDTO.quality.qualitaetElektroAnlage = realEstate.quality.qualitaetElektroAnlage;
          realEstateDTO.quality.qualitaetElektroAnlageBackend = realEstate.quality.qualitaetElektroAnlageBackend;
          realEstateDTO.quality.qualitaetHeizungsanlage = realEstate.quality.qualitaetHeizungsanlage;
          realEstateDTO.quality.qualitaetHeizungsanlageBackend = realEstate.quality.qualitaetHeizungsanlageBackend;
          realEstateDTO.quality.qualitaetSanitaeranlage = realEstate.quality.qualitaetSanitaeranlage;
          realEstateDTO.quality.qualitaetSanitaeranlageBackend = realEstate.quality.qualitaetSanitaeranlageBackend;
          realEstateDTO.quality.qualitaetAusbau1 = realEstate.quality.qualitaetAusbau1;
          realEstateDTO.quality.qualitaetAusbau1Backend = realEstate.quality.qualitaetAusbau1Backend;
          realEstateDTO.quality.qualitaetAusbau2 = realEstate.quality.qualitaetAusbau2;
          realEstateDTO.quality.qualitaetAusbau2Backend = realEstate.quality.qualitaetAusbau2Backend;
          realEstateDTO.quality.qualitaetUmgebung = realEstate.quality.qualitaetUmgebung;
          realEstateDTO.quality.qualitaetUmgebungBackend = realEstate.quality.qualitaetUmgebungBackend;

          return of(realEstateDTO);
        }),
      );
    }

    if (localStorage.getItem('replacementRealEstate') != null && route.queryParams['rebuildReplacement']) {
      if (localStorage.getItem('replacementRealEstate') == null) {
        return of(this.realEstateService.realEstateContainer as RealEstateDTO);
      }
      realEstate = JSON.parse(localStorage.getItem('replacementRealEstate') || '');
      this.presetWrapperService.realEstate = realEstate; // for fetching the reference(roofType)
      this.presetWrapperService
        .setCorrectSliderModelValues2(realEstate.usages)
        .pipe(untilDestroyed(this), take(1))
        .subscribe({});
      //local storage must be cleared when data is consumed (otherwise it will not work) - lufon.
      this.realEstateService.realEstateContainer = realEstate;
      realEstateByStateParam = realEstate;
      return of(realEstate);
    } else {
      realEstateByStateParam = realEstate;
      return this.loadRealEstate(
        realEstateIdByStateParam,
        realEstateByStateParam,
        state.url,
        state.url.includes('edit'),
        route.queryParams,
      ).pipe(
        switchMap((realEstate) => {
          return of(realEstate);
        }),
        catchError(() => {
          return EMPTY;
        }),
      );
    }
  }

  loadRealEstate(
    realEstateIdByStateParam: any,
    realEstateByStateParam: any,
    stateUrl: string,
    isEdit: boolean,
    queryParams: Params,
  ): Observable<RealEstateDTO | IframeInput> {
    this.realEstateService.isDataLoadedResponse$.next(false);
    if (realEstateIdByStateParam && realEstateByStateParam) {
      // this might happen if user loads a reference object (input new) and then edits this object
      // basically, frontend should make sure that realEstate stateParam is not set in this case, but it's quite hard to teach
      log.debug('Requested both realEstateIdByStateParam and realEstateByStateParam');
      return this.realEstateService.postProcessForEditRecordById({} as OutRealEstateDTO);
    }

    // load the real estate, either defined by state param realEstateId or realEstate
    //   log.debug('load rebuild realestate ',realEstateIdByStateParam,this.credentialsService.isAuthenticated())
    log.info('stateUrl', stateUrl);
    if (realEstateIdByStateParam !== null && this.credentialsService.isAuthenticated()) {
      if (
        !!queryParams['requestId'] &&
        (stateUrl.includes('public-process-form/edit') ||
          stateUrl.includes('public-rebuild-form/edit') ||
          stateUrl.includes('public-insurance-form/edit') ||
          stateUrl.includes('public-operational-form/edit'))
      ) {
        return this.realEstateService.getRealEstateByRequestId(queryParams['requestId']);
      }
      if (!!queryParams['requestId'] && stateUrl.includes('public-lcc-form/edit')) {
        console.error('queryParams', queryParams['requestId']);
        return this.realEstateService.getRealEstateByRequestId(queryParams['requestId']).pipe(
          take(1),
          tap((value) => {
            return this.lccDataService.processEntity(value);
          }),
        );
      }
      // this is checking small building.. we have this condition because we want to reuse the same resolver throught 6 forms
      if (stateUrl.includes('agv-small-building-form')) {
        if (isEdit) {
          // we are passing the id to postProcessForEditRecordById in small building service
          // create a copy of the real estate given the id
          // force load because in the meantime the realestate.metaData.numEstimations counter might have been incremented
          // console.warn('samll building + edit + realEstate.agv.externalVrNumber should append ');
          return this._smallBuildingService.getRealEstateById(realEstateIdByStateParam).pipe(
            take(1),
            mergeMap((realEstate) => {
              // console.log('reached next',realEstate);
              return this._smallBuildingService.postProcessForEditRecordById(realEstate);
            }),
          );
        } else {
          // console.warn('samll building + input + realEstate.agv.externalVrNumber should be empty ');
          return this._smallBuildingService.getEmptyRealEstate();
        }
      }
      if (stateUrl.includes('small-building-gvbs-form')) {
        if (isEdit) {
          // we are passing the id to postProcessForEditRecordById in small building service
          // create a copy of the real estate given the id
          // force load because in the meantime the realestate.metaData.numEstimations counter might have been incremented
          // console.warn('samll building + edit + realEstate.agv.externalVrNumber should append ');
          return this._smallBuildingService.getRealEstateById(realEstateIdByStateParam).pipe(
            take(1),
            mergeMap((realEstate) => {
              // console.log('reached next',realEstate);
              return this._smallBuildingService.postProcessForEditRecordById(realEstate);
            }),
          );
        } else {
          // console.warn('samll building + input + realEstate.agv.externalVrNumber should be empty ');
          return this._smallBuildingService.getEmptyRealEstate('SMALL_BUILDING_GVBS');
        }
      }
      // this is checking rebuild-form.. we have this condition because we want to reuse the same resolver throught 6 forms
      if (stateUrl.includes('rebuild-form')) {
        if (isEdit) {
          // create a copy of the real estate given the id
          // force load because in the meantime the realestate.metaData.numEstimations counter might have been incremented
          // console.log('Currently in rebuild-form + edit-record page');
          return this._rebuildEstateService.getRealEstateById(realEstateIdByStateParam).pipe(
            take(1),
            mergeMap((realEstate) => {
              // console.log('reached next',realEstate);
              return this._rebuildEstateDataService.postProcessForEditRecordById(realEstate);
            }),
          );
        } else {
          //return this.rebuildEstateService.getByIdCached({id: realEstateIdByStateParam}).finally();
          // console.log('Currently in rebuild-form + input-record page');
          return this._rebuildEstateDataService.getEmpty();
        }
      }
      // this is checking damage-form.. we have this condition because we want to reuse the same resolver throught 6 forms
      if (stateUrl.includes('damage-form')) {
        if (isEdit) {
          // create a copy of the real estate given the id
          // force load because in the meantime the realestate.metaData.numEstimations counter might have been incremented
          // console.log('Currently in damage-form + edit-record page');
          return this._rebuildEstateService.getRealEstateById(realEstateIdByStateParam).pipe(
            take(1),
            mergeMap((realEstate) => {
              // console.log('reached next',realEstate);
              return this._rebuildEstateDataService.postProcessForEditRecordById(realEstate);
            }),
          );
        } else {
          //return this.rebuildEstateService.getByIdCached({id: realEstateIdByStateParam}).finally();
          // console.log('Currently in damage-form + input-record page');
          return this._rebuildEstateDataService.getDamageEmpty();
        }
      }
      // this is checking for other 4 forms.. we have this condition because we want to reuse the same resolver throught 6 forms
      if (stateUrl.includes('gvbs')) {
        if (!isEdit) {
          return this.realEstateService.getEmptyRealEstate('INSURANCE_VALUES_GVBS');
        } else {
          return this.realEstateService.getRealEstateById(realEstateIdByStateParam).pipe(
            take(1),
            mergeMap((realEstate) => {
              // console.log('reached next',realEstate);
              return this.realEstateService.postProcessForEditRecordById(realEstate);
            }),
          );
        }
      }

      if (stateUrl.includes('insurance-form')) {
        if (!isEdit) {
          return this.realEstateService.getEmptyRealEstate('BUILDING_INSURANCE_VALUES');
        }
      }
      if (stateUrl.includes('lcc-form')) {
        if (!isEdit) {
          return this.lccDataService.getEmpty();
          // return this._rebuildEstateDataService.getEmpty();
          // return this.realEstateService.getEmptyRealEstate('REBUILD_COSTS');
        } else {
          return this.lccDataService.get(realEstateIdByStateParam);
        }
      }
      if (stateUrl.includes('lca-form')) {
        if (!isEdit) {
          return this.realEstateRepository.getEmpty('LCA', ['LCA']);
        } else {
          return this.realEstateRepository.get(realEstateIdByStateParam);
        }
      }
      if (stateUrl.includes('kv-to-bim')) {
        if (!isEdit) {
          return this.realEstateRepository.getEmpty('LCA', ['BUILDING_COSTS_AND_DATES', 'LCA', 'BIM_EVALUATION']);
        } else {
          return this.realEstateRepository.get(realEstateIdByStateParam);
        }
      }
      if (stateUrl.includes('process-form')) {
        if (!isEdit) {
          return this.realEstateRepository.getEmpty('LCA', ['BUILDING_COSTS_AND_DATES', 'LCA', 'BIM_EVALUATION']);
        } else {
          return this.realEstateRepository.get(realEstateIdByStateParam);
        }
      }
      if (stateUrl.includes('operational-form')) {
        if (!isEdit) {
          return this.realEstateService.getEmptyRealEstate('OPERATING_COSTS');
        }
      }
      if (!isEdit) {
        //return this.rebuildEstateService.getByIdCached({id: realEstateIdByStateParam}).finally();
        // console.warn(`${stateUrl.replace('/input', '')} + input + realEstate.metaData.name should be empty `);
        return this.realEstateService.getEmptyRealEstate('BUILDING_COSTS_AND_DATES');
      } else {
        // create a copy of the real estate given the id
        // force load because in the meantime the realestate.metaData.numEstimations counter might have been incremented
        // console.warn(`${stateUrl.replace('/edit', '')} + edit + realEstate.metaData.name should append`);
        return this.realEstateService.getRealEstateById(realEstateIdByStateParam).pipe(
          take(1),
          mergeMap((realEstate) => {
            // console.log('reached next',realEstate);
            return this.realEstateService.postProcessForEditRecordById(realEstate);
          }),
        );
      }
      // }
      log.error(`this should not happened! Missing form route Missing WTP to get the correct empty object.`);
      return EMPTY;
    } else {
      log.error(`user is not properly authenticated.`);
      this.credentialsService.isAuthenticated();
      this.authenticationService.logout();
      this.router.navigate(['/'], { replaceUrl: true });
      return EMPTY;
      // return this.rebuildEstateDataService.getEmpty();
    }
  }
}
